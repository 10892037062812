import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

interface ButtonProps {
  to: string
  children: string | ReactElement
}

const Button: React.FC<ButtonProps> = ({ to, children }) => (
  <Link
    to={to}
    className={classNames(
      'inline-block',
      'py-4 px-9',
      'rounded-lg',
      'bg-primary text-white text-center',
      'font-semibold text-base leading-none uppercase',
      'hover:scale-110 transition-transform duration-300 ease-in-out'
    )}
  >
    {children}
  </Link>
)
export default Button
