import React from 'react'
import classNames from 'classnames'

import Star from './Star'

interface IReviewStarsProps {
  /**
   * The number of stars.
   */
  of: number

  /**
   * The number of stars filled out of the max.
   */
  out?: number

  className?: string
}

const Stars: React.FC<IReviewStarsProps> = ({ className, of, out }) => {
  const num = parseInt(Math.abs(of).toString())
  const listOfStars = Array.from(Array(num).keys()).map(index => <Star key={index} />)
  return <div className={classNames('flex', className)}>{listOfStars}</div>
}
export default Stars
