import React from 'react'
import classNames from 'classnames'

interface IReviewItemProps {
  className?: string
  children?: React.ReactElement[] | React.ReactElement | string
}

const Item: React.FC<IReviewItemProps> = ({ children, className }) => (
  <div className={classNames('reviews-item px-2 py-2', className)}>{children}</div>
)
export default Item
