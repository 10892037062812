import InternalList from './List'
import Item from './Item'

type InternalListType = typeof InternalList

interface ListInterface extends InternalListType {
  Item: typeof Item
}

const List = InternalList as ListInterface
List.Item = Item
export default List
