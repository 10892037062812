import React from 'react'
import classNames from 'classnames'

import Section from '../../../components/kabana/Section'
import Divider from '../../../components/kabana/Divider'
import Button from '../../../components/kabana/Button'
import Review from '../../../components/kabana/Review'
// import Video from '../../components/Video'

//import logoHorizontal from '../../../assets/images/kabana-tribe-logo-horizontal.png'
import backgroundCoverImage from '../../../assets/images/covers/beautiful-pool-in-nature-1.jpg'
// @ts-ignore
import introductionVideo from '../../../assets/videos/intro.mp4'

interface IHeaderSectionProps {
  wallet:any;
  userTier:any
  nftCount:any
  
}
const HeaderSection: React.FC<IHeaderSectionProps> = (props) => {
  const { userTier, wallet, nftCount} = props;
  return(
  <Section
    header
    gradient
    image={backgroundCoverImage}
    className={classNames('flex flex-col items-center', 'py-4', 'text-white')}
  >
    <Section.Container className="lg:py-8">
      <div
        className={classNames(
          'intro-content',
          'lg:grid lg:grid-cols-2 lg:gap-x-8 lg:auto-rows-min',
          'mb-4 lg:mb-10',
          'text-center lg:text-left'
        )}
      >
        {/***
         * Group #1: Title, Subtitle and Divider.
         */}
        <div className="intro-heading lg:px-4" style={{marginTop:'40px', padding:'0px 15px'}}>
          {/* <img
            className="hero-logo w-44 md:w-64 mb-4 mx-auto lg:mx-0"
            src={logoHorizontal}
            alt="Kabana Tribe"
          /> */}
          <h1 className="hero-title font-bold">
            BALI’S FIRST LUXURY  LIFETIME MEMBERSHIP ON THE BLOCKCHAIN
          </h1>
          <h2 className="hero-subtitle text-lg font-normal">
            JOIN THE WORLD-FAMOUS LUXURY LEISURE EXPERIENCE
          </h2>
          <Divider className="mb-4 mx-auto lg:mx-0" />
        </div>

        {/**
         * Group #2: Video.
         */}
        <div
          className={classNames(
            'intro-video-container',
            'flex items-center mb-4',
            'lg:col-start-2 lg:col-end-3',
            'lg:row-start-1 lg:row-end-3',
            'lg:self-start',
            'lg:pl-6 lg:translate-y-20'
          )}
        >
          <video className="" src={introductionVideo} controls controlsList="nodownload" />
          {/* <Video
          options={{
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [
              {
                src: introductionVideo,
                type: 'video/mp4',
              },
            ],
          }}
        /> */}
        </div>

        {/**
         * Group #3: Description and CTA.
         */}
        <div
          className={classNames(
            'intro-body lg:px-4'
            // 'lg:col-start-2 lg:col-end-3',
            // 'lg:row-start-1 lg:row-end-2'
          )}
        >
          <p className="hero-description mb-8 lg:text-lg">
            Have access to our prestigious Bali venues, elite 
            transportation services, exclusive private events, become 
            partners in new venues and much more!
          </p>
          <div className="hero-action">
           {userTier !== undefined && nftCount === 0 && wallet !== '' ?  <Button to="/presale/bsc">Purchase Exclusive KClub Membership NFT</Button> :<></>}
          </div>
        </div>
      </div>

      <div className={classNames('intro-reviews', 'flex flex-col items-center py-4')}>
        <Review>
          <Review.Item className="hidden md:block">Our customers say:</Review.Item>
          <Review.Item className="shrink-0">Excellent</Review.Item>
          <Review.Item>
            <Review.Stars className="items-center" of={5} />
          </Review.Item>
          <Review.Item className="hidden md:block">4.9 out of 5 based on 469 reviews</Review.Item>
          <Review.Item className="shrink-0">
            {/* Google Reviews */}
            <img
              style={{ width: '100px' }}
              src="../images/assets/output-onlinepngtools-66.png"
              alt="Google Reviews"
            />
          </Review.Item>
        </Review>
      </div>
    </Section.Container>
  </Section>
  )
}
 
export default HeaderSection
