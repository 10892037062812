import InternalReview from './Review'
import Item from './Item'
import Stars from './Stars'

type InternalReviewType = typeof InternalReview
interface ReviewInterface extends InternalReviewType {
  Item: typeof Item
  Stars: typeof Stars
}

const Review = InternalReview as ReviewInterface
Review.Item = Item
Review.Stars = Stars
export default Review
