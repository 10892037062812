import React from 'react'
import Slider from '../Slider/Slider'
import { Settings } from 'react-slick'

type ImageSliderProps = {
  images: string[]
  settings?: Settings
  className?: string
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, settings, className }) => (
  <Slider settings={settings}>
    {images.map((image, index) => (
      <figure key={index} className={`slide-inner ${className}`}>
        <img className='slide-image object-cover w-full h-full' alt="Slide element" src={image} />
      </figure>
    ))}
  </Slider>
)

export default ImageSlider
