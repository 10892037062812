import React from 'react'
import SlickSlider, { Settings } from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Slider.css'

type SliderType = {
  className?: string
  children?: React.ReactElement | React.ReactElement[] | string
  settings?: Settings
}

const Slider: React.FC<SliderType> = ({ className, children, settings }) => {
  const attributes = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...settings,
  }

  return (
    <SlickSlider className={className} {...attributes}>
      {children}
    </SlickSlider>
  )
}

export default Slider
