import React from 'react'
import classNames from 'classnames'

interface IListProps {
  /**
   * Is an ordered list.
   */
  ordered?: boolean
  children: React.ReactElement | React.ReactElement[] | string
  className?: string
}

/**
 *
 * @param props
 * @returns
 */
const List: React.FC<IListProps> = props => {
  const { className, children, ordered } = props
  const componentlassName = classNames('list-inside', 'mb-8', 'space-y-2')

  return (
    <>
      {ordered ? (
        <ol {...props} className={classNames(componentlassName, 'list-decimal', className)} children={children} />
      ) : (
        <ul {...props} className={classNames(componentlassName, 'list-disc', className)} children={children} />
      )}
    </>
  )
}
export default List
