import React from 'react'
import classNames from 'classnames'

interface IReviewProps {
  className?: string
  children?: React.ReactElement[] | React.ReactElement
}

const Review: React.FC<IReviewProps> = ({ children, className }) => (
  <div
    className={classNames(
      'reviews flex items-center',
      'border-x border-y border-x-white border-y-white rounded-lg',
      'text-xs font-bold',
      'drop-shadow-2xl',
      className
    )}
  >
    {children}
  </div>
)
export default Review
