import React from 'react'
import classNames from 'classnames'

import './Hero.css'

interface IHeroProps {
  /**
   * A Hero can be reversed.
   */
  reversed?: boolean
  children: React.ReactElement | React.ReactElement[] | string
  className?: string
}

const Hero: React.FC<IHeroProps> = props => {
  const { className, children, reversed } = props
  return (
    <div
      {...props}
      className={classNames(
        'hero',
        'lg:grid lg:grid-cols-2 lg:gap-16 lg:items-center lg:grid',
        { reversed: reversed },
        className
      )}
      children={children}
    />
  )
}
export default Hero
