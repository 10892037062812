//REACT
import React, { useEffect, useState, useCallback } from 'react';
import { Route, BrowserRouter as Router, Switch ,Link} from 'react-router-dom';
  
 
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//FONTAWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//UTILITY
import { useTranslation } from 'react-i18next';

//APP COMPONENTS

//PAGES
import Footer from './Footer';
import TCPage from './screens/terms/TCPage';
import PPPage from './screens/privacy/PPPage';

//Styles
import 'font-awesome/css/font-awesome.min.css';
//INTERNAL STYLES
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Header.css'; 
import './CustomStyle.css'; 
 
import { containerStyle } from './styles/styles';
import KabanaHome from './screens/kabana-home/KabanaHome'

function App() {
  const [userAccountIsBlocked] = useState(false);
  const [walletAccount] = useState('');
  const [currentUser] = useState();
  const [isConnected] = useState(false);
  const [nightMode, setNightMode] = useState(false);
  const [loading] = useState(false);
  const [role] = useState('user');
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [navigation] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [isItMobile, setMobile] = useState(false);
  const [pathSelected, setPathSelected] = useState('');
  const [setCurrentNavigationPage] = useState({});
  const [referrer] = useState();
  const [nftAmount] = useState();
  var [bearerTocken] = useState('');

const {i18n } = useTranslation();

  // Effects
  useEffect(() => {
  }, [isConnected]);

  const  clearStorage =(ary) =>{
    if(sessionStorage){
      sessionStorage.clear();
    }
    if(localStorage){
      localStorage.clear();
    }
   
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
        if((ca[i]+'').split('=').length > 0){
          var cname = (ca[i]+'').split('=')[0];
          var cval= (ca[i]+'').split('=')[1]
          const d = new Date();
          d.setTime(d.getTime() + (30*24*60*60*1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" +cval+";" + expires + ";path=/";
        }
     
      }
       
    }
    document.location.reload();
  }

  const isMobileDevice = () => {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return 'ontouchstart' in window || 'onmsgesturechange' in window;
      
    }
  };
  const handleResize = useCallback(() => {
    var currentMIsItMobile = isMobileDevice();
    setMobile(currentMIsItMobile);
  }, []);
  window.addEventListener('popstate', e => {
    if (isMounted === true) {
      if (navigation !== undefined) {
        for (var bb = 0; bb < navigation.length; bb++) {
          if (navigation[bb].children !== undefined) {
            for (var vv = 0; vv < navigation[bb].children.length; vv++) {
              if (navigation[bb].children[vv].route === window.location.pathname) {
                setCurrentNavigationPage(navigation[bb].children[vv]);
                trigger('NavSelected', navigation[bb].children[vv]);
              }
            }
          } else {
            if (navigation[bb].route === window.location.pathname) {
              setCurrentNavigationPage(navigation[bb]);
              trigger('NavSelected', navigation[bb]);
            }
          }
        }
      }
      setPathSelected(window.location.pathname);
    }
  });
  document.addEventListener('clickedNav', e => {
    
  });
  window.addEventListener('resize', handleResize);
  document.addEventListener('DOMContentLoaded', event => {
  
  });

  const trigger = useCallback(
    (eventType, data) => {
      if (isMounted === true) {
        const event = new CustomEvent(eventType, { detail: data });
        document.dispatchEvent(event);
      }
    },
    [isMounted],
  );

  window.addEventListener('onhashchange', e => {
    setTimeout(() => {
      if (isMounted === true) {
        if (navigation !== undefined) {
          for (var bb = 0; bb < navigation.length; bb++) {
            if (navigation[bb].children !== undefined) {
              for (var vv = 0; vv < navigation[bb].children.length; vv++) {
                if (navigation[bb].children[vv].route === window.location.pathname) {
                  setCurrentNavigationPage(navigation[bb].children[vv]);
                  trigger('NavSelected', navigation[bb].children[vv]);
                }
              }
            } else {
              if (navigation[bb].route === window.location.pathname) {
                setCurrentNavigationPage(navigation[bb]);
                trigger('NavSelected', navigation[bb]);
              }
            }
          }
        }
        setPathSelected(window.location.pathname);
      }
    }, 500);
  });
  window.addEventListener('mouseup', e => {
    //your code goes here on location change
    setTimeout(() => {
      if (isMounted === true) {
        //////console.log("################################33")
        if (navigation !== undefined) {
          for (var bb = 0; bb < navigation.length; bb++) {
            if (navigation[bb].children !== undefined) {
              for (var vv = 0; vv < navigation[bb].children.length; vv++) {
                if (navigation[bb].children[vv].route === window.location.pathname) {
                  setCurrentNavigationPage(navigation[bb].children[vv]);
                  trigger('NavSelected', navigation[bb].children[vv]);
                }
              }
            } else {
              if (navigation[bb].route === window.location.pathname) {
                setCurrentNavigationPage(navigation[bb]);
                trigger('NavSelected', navigation[bb]);
              }
            }
          }
        }
        setPathSelected(window.location.pathname);
      } else {
        setTimeout(() => {
          if (isMounted === true) {
            if (navigation !== undefined) {
              for (var bb = 0; bb < navigation.length; bb++) {
                if (navigation[bb].children !== undefined) {
                  for (var vv = 0; vv < navigation[bb].children.length; vv++) {
                    if (navigation[bb].children[vv].route === window.location.pathname) {
                      setCurrentNavigationPage(navigation[bb].children[vv]);
                      trigger('NavSelected', navigation[bb].children[vv]);
                    }
                  }
                } else {
                  if (navigation[bb].route === window.location.pathname) {
                    setCurrentNavigationPage(navigation[bb]);
                    trigger('NavSelected', navigation[bb]);
                  }
                }
              }
            }
            setPathSelected(window.location.pathname);
          }
        }, 500);
      }
    }, 800);
  });

  // Handle Disconnected. Removing the state of the account connected
  // to your app should be enough to handle Disconnect with your application.

  //INITIALIZE APP + METAMASK EVENTS
  useEffect(() => {
    setIsMounted(true);
    if (isMounted === true) {
      var currentMIsItMobile = isMobileDevice();
      setMobile(currentMIsItMobile);
      setNightMode(nightMode);
      setPathSelected(window.location.pathname);
    }
  }, [
    i18n.language,
    isMounted,
    handleResize,
    nightMode,
    walletAccount,
    bearerTocken,
  ]);
 

  return (
    <div className="App">
      <div style={{ display: userAccountIsBlocked === true ? 'none' : 'block' }}>
        <Router>
          {/* TOP RIGHT-HAND HEADER BUTTON SECTION */}
          <div className="container" style={containerStyle}>
            <div className="row" style={{ paddingTop: '50px' }}>

              {walletAccount && walletAccount !== '' && isConnected ? (
                <div
                  className="text-right"
                  style={{
                    color: nightMode ? 'inherit' : 'inherit',
                    zIndex: '9999999',
                    position: 'fixed',
                    float: 'right',
                    right: '0px',
                    top: '0px',
                    width: 'auto',
                  }}
                >
                  <span
                    style={{
                      color: nightMode ? 'inherit' : 'inherit',
                      position: 'fixed',
                      zIndex: '99999',
                      float: 'right',
                      right: '0px',
                      top: '0px',
                      width: 'auto',
                      pointerEvents: 'auto',
                    }}
                  >
                    <span
                      style={{
                        zIndex: '22',
                        color: nightMode ? 'inherit' : 'inherit',
                        cursor: 'pointer',
                        padding: '5px 10px',
                        position: 'relative',
                        float: 'right',
                        right: '0px',
                        top: '0px',
                        width: '100%',
                      }}
                    >

                      {role === 'admin' ? (
                        <span
                          title="User Administration Management"
                          style={{
                            padding: '0px',
                            marginRight: '3px',
                            color: 'inherit',
                            float: 'left',
                          }}
                        >
                          <Link
                            to="/admin"
                            className={pathSelected === '/admin' ? ' btn btn-ekta' : 'text-ekta'}
                            style={{


                              color: pathSelected === '/admin' ? 'inherit' : 'inherit',
                              float: 'left',
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                padding: '0px 0px',
                                color: pathSelected === '/admin' ? 'inherit' : 'inherit',
                              }}
                              icon={'fa fa-cog fa-fw '}
                            />
                          </Link>
                        </span>
                      ) : (
                        <></>
                      )}

                      { walletAccount && walletAccount !== '' && isConnected && !loading?
                      <span title="User Details" style={{  padding: '0px', marginRight: '3px', float: 'left' }} className={pathSelected === '/settings' ? ' ' : 'text-ekta'}>
                        <Link
                          to="/settings"
                          className="pull-left"
                          style={{
                            color: 'inherit',
                            float: 'left',
                            padding: '0px 0px',
                            borderRadius: '6px',
                            marginRight: '0px',   }}  >
                          <button className={pathSelected === '/settings' ? 'btn btn-ekta' : ' btn btn-default'}
                            style={{ padding: '5px 12px', color: 'inherit' }}  >
                          <span  className=" fa fa-user fa-fw"></span>
                        </button>
                        </Link>
                      </span>
                      :<></>}
                      <button   style={{marginTop:'0px', marginRight:'10px', display:'inline-block'}}
                        className="pull-right btn btn-ekta  pull-left"
                        disabled={ !localStorage ? true : false}
                        onClick={e => clearStorage()}
                      >
                        <span className="fa fa-refresh fa-fw"></span>
                      </button>
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  className="text-right"
                  style={{
                    color: nightMode ? '#fff' : '#000',
                    position: 'fixed',
                    zIndex: '9999999',
                    float: 'right',
                    right: '0px',
                    top: '0px',
                    width: 'auto',
                  }}
                >
                  <span
                    style={{
                      zIndex: '22',
                      color: nightMode ? '#fff' : '#000',
                      cursor: 'pointer',
                      padding: '5px 10px',
                      position: 'relative',
                      float: 'right',
                      right: '0px',
                      top: '0px',
                    }}
                  >
                  </span>
                </div>
              )}
            </div>
          </div>
          {/*PRO SIDEBAR */}
          <div
            id={isItMobile ? 'header-mobile' : 'header'}
            style={{
              maxWidth: menuCollapse ? 'auto' : 'auto',
              top: '49px',
              overflow: 'auto',
              height: '107vh',
              zIndex: '19999999',
              borderRight: '0.5px solid rgb(140 140 140 / 42%)',
            }}
            className="custom-slider" >

          </div>
          <header
            style={{
              textAlign: isItMobile ? 'left' : 'left',
              float: 'left',
              width: '100%',
              zIndex: '99999',
              height: '60px',

              overflow: 'hidden',
              position: 'fixed',
              left: '0px',
              top: '0px',
              borderBottom: '0.5px solid rgb(140 140 140 / 42%)',
              padding: '5px 8px',
              margin: '0px',
              cursor: 'pointer',
            }} className="custom-slider">
            {/* {isItMobile ? (
              <>
                <i
                  style={{
                    color: nightMode ? 'inherit' : 'inherit',
                    padding: '5px 0px 0px 5px',
                    marginLeft: '0px',
                    marginRight: !menuCollapse ? '0px' : '10px',
                  }}
                  onClick={() => {
                    menuIconClick();
                  }}
                  className={menuCollapse ? 'fa fa-bars fa-large text-dynamic pull-left' : 'fa fa-angle-right fa-sm text-dynamic pull-left'}
                  id="trigger"
                ></i>
                {!menuCollapse ? (
                  <i
                    style={{
                      color: nightMode ? 'inherit' : 'inherit',
                      padding: '5px 0px 0px 5px',
                      marginLeft: '-8px',
                      marginRight: '17px',
                    }}
                    onClick={() => {
                      menuIconClick();
                    }}
                    className={menuCollapse ? 'fa fa-angle-left fa-large text-dynamic pull-left' : 'fa fa-angle-left fa-sm text-dynamic pull-left'}
                    id="trigger"
                  ></i>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )} */}
            <Link to="/">
              <img
                src="../icons/logo.png"
                alt=" "
                style={{
                  display: 'inline-block',
                  height: isItMobile ? '36px' : '40px',
                  width: 'auto',
                  marginTop: '5px',
                  marginRight: '10px',
                }}
              />
            </Link>


            <div
              onMouseOver={() => setMenuCollapse(true)}
              style={{
                display: 'inline-block',
                position: 'fixed',
                zIndex: '99',
                top: '50px',
                left: isItMobile ? '0px' : '50px',
                width: isItMobile ? 'calc(100% - 0px)' : 'calc(100% - 50px)',
                pointerEvents: 'none',
                height: 'auto',
                backgroundColor: 'transparent',
                color: nightMode ? '#fff' : '#333',
                transition: 'width, left, right, 0.3s',
              }} >
              {!loading && bearerTocken !== '' ? (
                <span
                  style={{
                    display: !loading && pathSelected !== '/admin' ? 'block' : 'none',
                    backgroundColor: 'transparent',
                  }} >
                  <div  className="custom-subheader"></div>
                </span>
              ) : (
                <></>
              )}
            </div>
          </header>
          {/* MAIN CONTAINER */}
          <div
            id="main"
            onClick={() => setMenuCollapse(true)}
            onMouseOver={() => setMenuCollapse(true)}
          >
            <Switch>
              <Route
                exact
                currentWallet={walletAccount}
                path="/"
                render={props => <KabanaHome userId={currentUser} nftAmount={nftAmount} currentWallet={walletAccount} isMobile={isItMobile} tierId={'1'} referralId={referrer} />}
              ></Route>

              {/* TERMS AND CONDITIONS PAGE */}
              <Route
                exact
                path="/terms-and-conditions"
                render={
                  props => (
                    <TCPage
                      {...props}
                    />
                  )
                }
              ></Route>
              {/* PRIVACY POLICY PAGE */}
              <Route
                exact
                path="/privacy-policy"
                render={
                  props => (
                    <PPPage
                      {...props}
                    />
                  )
                }
              ></Route>

            </Switch>
          </div>
          {/*  TOAST NOTIFICATIONS + FOOTER */}
          <div
            style={{
              color: nightMode ? '#fff' : '#000',
              backgroundColor: nightMode ? '#240C3B' : '#fff' }} >
            {/* FOOTER */}
            <Footer />
          </div>
        </Router>
      </div>
      <div
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          marginLeft: '-25%',
          marginTop: '-75px',
          width: '50%',
          height: '250px',
          display: userAccountIsBlocked === true ? 'block' : 'none',
        }}
      >
        <div className="row">
          <div className="col-md-12 col-xs-12 pull-left text-center">
            <h4 className="text-danger text-center">
              <span className="fa fa-exclamation-triangle fa-fw fa-4x"></span> <br />
              <br />
              <strong>Error:</strong> <br /> Site is under maintenance please check back later.
              <br />
              Portal administration message.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default () => (
//       <App style={{ paddingBottom: '0px ' }} />
// );

export default App;
