import React from 'react'
import classNames from 'classnames'

const Text: React.FC<{
  className?: string
  children: React.ReactElement | React.ReactElement[] | string
}> = props => {
  const { className, children } = props
  return (
    <p
      {...props}
      className={classNames('card-text', 'md:text-justify', className)}
      children={children}
    />
  )
}
export default Text
