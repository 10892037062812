import InternalHero from './Hero'
import Media from './Media'
import Body from './Body'

type InternalHeroType = typeof InternalHero
interface InternalHeroInterface extends InternalHeroType {
  Body: typeof Body
  Media: typeof Media
}

const Hero = InternalHero as InternalHeroInterface
Hero.Body = Body
Hero.Media = Media
export default Hero
