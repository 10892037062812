import React, {useEffect,useState} from 'react'
import classNames from 'classnames'
import SEO from '../../components/kabana/SEO'
import Section from '../../components/kabana/Section'
import Footer from '../../components/kabana/Footer'
import Card from '../../components/kabana/Card'
import Hero from '../../components/kabana/Hero'
import List from '../../components/kabana/List'
import HeaderSection from './sections/Header'
import Container from '../../components/kabana/Container'
import ImageSlider from '../../components/kabana/Slider/ImageSlider'
import ImageSliderMember from '../../components/kabana/Slider/ImageSliderMember'
import data from '../../data/data.json'
import firstGapImageBackground from '../../assets/images/covers/beautiful-bamboo-infrastructure-1.jpg'

interface IKabanaHomeProps {
  tierId: string;
  referralId: string;
  isMobile:any;
  currentWallet:any;
  nftAmount:any;
  userId:any;
}
const KabanaHome: React.FC<IKabanaHomeProps> = props => {
  const {tierId, referralId ,isMobile,currentWallet, userId, nftAmount} = props;
  const [wallet] = useState<any>('');
  const [nftCount] = useState<any>();
  const [setUserId] = useState<any>();
  const [userTier, setCurrentTierObj] = useState<any>();
    let currentTierObj: any;

    useEffect(() => {
        currentTierObj = {
            1:{
                key:"1",

                "PREFERENTIAL_PRICING":{
                    title:"SPECIAL PRICING ACROSS ALL K-CLUB GROUP:",
                    list:[
                        "Up to 25% off K Club accommodations",
                        "10% off beverage at K Club Group",
                        "50% off food in all K Club restaurants"

                    ]},
                "EVENTS":{
                    title:"Access to VIP Private Events:",
                    list:[
                        "12 events per year with top international DJ's"
                    ]},
                "CONCIERGE":{
                    title:"THROUGH THE MEMBERS-ONLY APP, MEMBERS CAN USE THE UTILITIES BELOW.",
                    list:[
                        " Access to a dedicated 24/7 VIP Concierge service"
                    ]},
                "NFT":{
                    title:"NFT TOKEN & PROPORTIONAL SHARES OF KABANA UBUD:",
                    list:[
                        "The NFT represents your digital contract and is made of artwork created by the famous Balinese artist Monez!"
                    ]},
                "COMMUNITY":{
                    title:"Become a member of a new kind and unique community in Bali:",
                    list:[
                        "The KABANA TRIBE Membership allows you to grow your asset in a few months.",
                        "Today, you have the opportunity to become one of the 50 silent investors by referring 10 like-minded investors who’d like to join the Kabana Tribe.",
                        "Can you think of 10 friends who’d love to be part of an exclusive membership club like Kabana Tribe?",
                        "If you’re worried that you can’t think of 10 friends, each new member you’ll manage to refer us will get you a 10% commission for every sale!",
                        "If you’re reading this, it is because we believe you do!"
                    ]
                },
                "TODO":{
                    title:"Here is what you need to do:",
                    subTitle:"If you manage to be first to get 10 people to join the KABANA TRIBE, you will receive your one of 50 Silent Investor membership (worth $10,000 in equity and certified by a notary).",
                    list:[
                        "Share your personal referral link with your network.",
                        "Be first of 50 to get 10 people to purchase Exclusive KClub Membership NFT!"
                    ]
                },
                "INFO":{
                    title:"The project is backed by Bali's most prominent real estate developer:",
                    list:[
                        "Unlike most NFT projects that sell you a potential future, we offer you real-life benefits that you can use immediately.",

                    ]
                },
                "MEMBER":{
                    title:"THE EARLY-BIRD PRIVATE SALE PRICE FOR KABANA TRIBE MEMBERSHIP STARTS AT $2000 USD. THIS GETS YOU:",
                    list:[
                        "A lifetime membership to all K-Club locations and the partner venues,",
                        "The unique opportunity to become a partner of facilities with member rewards,",
                        "The growth of asset value over the years,",
                        "Access to a community of international VIPs,",
                        "A Non-Fungible token,",
                        "Bali’s most prominent real estate developer, Kibarer Property, developed this project.",
                    ]
                }

            },
            2:{
                key:"2",

                "PREFERENTIAL_PRICING":{
                    title:"Preferential pricing across all KCLUB GROUP:",
                    list:[
                        "50% on food in K Club Group (Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Kabana Ceningan).",
                        "25% discounts on a selection of prestigious venues in Bali (beach clubs, hotels, restaurants)",
                        "10% off beverages at K-Club Group."
                    ]},
                "CONCIERGE":{
                    title:"Access to a VIP Concierge service through our proprietar app:",
                    list:[
                        "A fleet of yachts and helicopter shuttle service to travel between our Bali resorts: Ubud, Canggu, Nusa Dua, Ceningan.",
                        "A dedicated 24/7 concierge service for our members"
                    ]},
                "EVENTS":{
                    title:"Access to VIP Private Events:",
                    list:[
                        "The entrance will be restricted to members only.",
                        "12 events per year with top international DJ's."
                    ]},
                "NFT":{
                    title:"NFT TOKEN & PROPORTIONAL SHARES OF KABANA UBUD:",
                    list:[
                        "The NFT artworks are designed by the top Indonesian artist, Monez.",
                        "You can trade your NFT. After the minting, the value of your NFT will be at least 3x. ",
                        "You will get shared parts on Bali’s fastest growing luxury group."
                    ]},
                "COMMUNITY":{
                    title:"Become a member of a new kind and unique community in Bali:",
                    list:[
                        "The KABANA TRIBE Membership allows you to grow your asset in a few months.",
                        "Today, you have the opportunity to become one of the 500 silent investors by referring 5 like-minded investors who would like to join the Kabana Tribe.",
                        "Can you think of 5 friends who would love to be part of an exclusive membership club like Kabana Tribe?",
                        "If you’re worried that you can’t think of 5 friends, each new member you’ll manage to refer us, will get you a 10% commission for every sale!",
                        "If you’re reading this, it is because we believe you do!"
                    ]
                },
                "TODO":{
                    title:"Here is what you need to do:",
                    subTitle:"If you manage to be first to get 5 people to join the KABANA TRIBE, you will receive your one of 500 Silent Investor membership (worth $5,000 in equity and certified by a notary).",
                    list:[
                        "Share your personal referral link with your network.",
                        "Be first of 100 to get 5 people to purchase Exclusive KClub Membership NFT!"

                    ]
                },
                "INFO":{
                    title:"The project is backed by Bali's most prominent real estate developer:",
                    list:[
                        "Unlike most NFT projects that sell you a potential future, we offer you real-life benefits that you can use immediately.",

                    ]
                },
                "MEMBER":{
                    title:"This very limited KABANA TRIBE Membership sale at $1000 gets you:",
                    list:[
                        "A lifetime membership to all K Club locations in Indonesia",
                        "An unique opportunity to become a silent investor",
                        "A great potential to grow your asset value over the years",
                        "Access to Bali's most exclusive private clubs",
                        "The ownership of Bali's first NFT KClub token",
                        "An early-bird $1000 Membership initially valued at $3000",
                    ]
                },

            },
            3:{
                key:"3",

                "PREFERENTIAL_PRICING":{
                    title:"Preferential pricing across all KCLUB GROUP:",
                    list:[
                        "50% on food in K Club Group (Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Kabana Ceningan).",
                        "25% discounts on a selection of prestigious venues in Bali (beach clubs, hotels, restaurants)",
                        "10% off beverages at K-Club Group."
                    ]},
                "CONCIERGE":{
                    title:"Access to a VIP Concierge service through our proprietar app:",
                    list:[
                        "A fleet of yachts and helicopter shuttle service to travel between our Bali resorts: Ubud, Canggu, Nusa Dua, Ceningan.",
                        "A dedicated 24/7 concierge service for our members"
                    ]},
                "EVENTS":{
                    title:"Access to VIP Private Events:",
                    list:[
                        "The entrance will be restricted to members only.",
                        "12 events per year with top international DJ's."
                    ]},
                "NFT":{
                    title:"NFT's:",
                    list:[
                        "The NFT artworks are designed by the top Indonesian artist, Monez.",
                        "You can trade your NFT. After the minting, the value of your NFT will be at least 3x. "
                    ]},
                "MEMBER":{
                    title:"This very limited KABANA TRIBE Membership sale at $1000 gets you:",
                    list:[
                        "A lifetime membership to all K Club locations in Indonesia",
                        "A great potential to grow your asset value over the years",
                        "Access to Bali's most exclusive private clubs",
                        "The ownership of Bali's first NFT KClub token",
                        "An early-bird $1000 Membership initially valued at $3000",
                    ]
                },
                "TODO":{
                    title:"GET 1500$ VOUCHER:",
                    list:[
                        "500$ Food voucher to spend at world class restaurant Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Ceningan and Gili Gede",
                        "500$ Voucher for accommodation (K Club Villas)",
                        "500$ for VIP services (yacht , helicopter)"
                    ]
                },
                "COMMUNITY":{
                    title:"Become a member of a new kind and unique community in Bali:",
                    list:[
                        "Today acquiring a Kabana Tribe NFT Membership will allow you to be part of an exclusive private club with access to unique luxury lifestyle properties, experiences and premium VIP events with International DJ's across Indonesia… while your membership value keeps growing! ",

                    ]
                }
            }
        };
         setCurrentTierObj(currentTierObj[1]);
    }, [tierId, referralId, currentWallet, nftAmount, userId, currentTierObj, setUserId]);

  
  return (
    <div
      className="App-header"
      style={{
        left: '0px',
        position: 'relative',
        top: '0px',
        backgroundColor: 'inherit',
        color: 'inherit',
        width: 'calc(100%)',
        margin: '0px 0px',
        padding: '0px 0px 0px 0px',
        paddingLeft: isMobile  ? '0px':'0px',
      }}
  >
      <SEO title="Kabana Tribe" />

      {/** HEADING SECTION */}
      <HeaderSection nftCount={nftCount} wallet={wallet} userTier={userTier} />
      <Section gap image={firstGapImageBackground} />

      {/** MEMBERSHIP SECTION */}
      <Section className={classNames('bg-secondary', 'text-center text-white')}>
        {/* — Title */}
        <div className={classNames('bg-primary', 'lg:pb-8')}>
          <Container>
            <div
              className={classNames('bg-secondary rounded-lg', 'px-2 py-4 -translate-y-6 lg:p-14')}
            >
              <h1 className="mb-0">Introducing : Kabana Tribe Membership</h1>
            </div>
          </Container>
        </div>

        {/* — Introduction */}
        <Container className="py-10 md:max-w-2xl ">
        <p className="mt-10">
          As a member, you will be able to instantly use our utilities. You will get VIP access to all 
          our private events, special prices and receive membership rewards as a partner.
        </p>
        <p className="mt-10"> </p>
        <h2 className="text-xl ">The exclusive Kabana Membership includes:</h2>
        
           
        </Container>

        {/* — Carousel */}
        <Container className="mb-10">
          <ImageSlider
            className="w-full h-96 md:w-60 md:h-64"
            images={[
              '../images/kclub/Copie-de-Copy-of-DSC01007-pqljpysbizi15efjg3q1yieyuf4m7pe5tr4x7qu3z4.jpg',
              '../images/kclub/Copie-de-Copy-of-7U7A2218-1-1-pqljq4fcnzpr327cj65tdgzqeqcthw0juj1u3elqxs.jpg',
              '../images/kclub/Copie-de-Copy-of-7U7A2102-1-pqljq6b11nsbqa4m86z2iginli3jxa80isct1yiylc.jpg',
              '../images/kclub/Copie-de-Copy-of-DSC00980-pqlkmphxl8blkeve9987t8yzrlqwc5dkbt7t0qaf8g.jpg',
              '../images/kclub/DSC00068-1-ppri8472znzui20qs8dkmtzga948pvpil37z7cfyqo.jpg',
              '../images/kclub/Copie-de-Copy-of-7U7A1786-pqlkmhz82k1azj6bh5z79avb0irymkjpmrzx6ilkm8.jpg',
            ]}
            settings={{
              dots: false,
              arrows: false,
            }}
          />
        </Container>

        {/* — Offers */}
        <Container className="max-w-20 mb-10"  >
          <div className={classNames('grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3   mb-10')}>
            <Card className="card space-y-4 text-left lg:order-1 sm:order-1">
              <Card.Body>
                <Card.Title className="lg:mb-6 text-left">
                 { userTier !== undefined 
                //   
                 ?  userTier["PREFERENTIAL_PRICING"].title :''}
                </Card.Title>
                <ul className="list-disc list-inside lg:space-y-4 lg:pl-4" style={{padding:'0px', paddingRight:'10px' }}>
                { userTier !== undefined ? 
                  userTier["PREFERENTIAL_PRICING"].list.map( (obj:any, index:any) => 
                  <List.Item key={"list_pricing"+index}    > {obj} </List.Item>
                )
              :<></>
              }
                </ul>
              </Card.Body>
            </Card>
            <Card className="card space-y-4 text-left lg:order-2 sm:order-2">
              <Card.Body >
                <Card.Title className="lg:mb-6 text-left" >
                { userTier !== undefined  ?  userTier["CONCIERGE"].title :''}
                </Card.Title>
                <ul className="list-disc list-inside lg:space-y-4 lg:pl-4" style={{padding:'0px', paddingRight:'10px' }}>
                  
                  { userTier !== undefined  ? 
                    userTier["CONCIERGE"].list.map( (obj:any, index:any) => 
                    <List.Item  key={"list_pricing"+index}    > {obj} </List.Item >
                    )
                  :<></>}
                </ul>
              </Card.Body>
            </Card>
            <Card className="card space-y-4 text-left lg:order-3 sm:order-3">
              <Card.Body >
                <Card.Title className="lg:mb-6 text-left">{ userTier !== undefined  ?  userTier["EVENTS"].title :''}</Card.Title>
                <ul className="list-disc list-inside lg:space-y-4 lg:pl-4" style={{padding:'0px', paddingRight:'10px' }}>
                   
                  { userTier !== undefined  ? 
                    userTier["EVENTS"].list.map( (obj:any, index:any) => 
                    <List.Item  key={"list_pricing"+index}    > {obj} </List.Item > )
                  :<></>}
                </ul>
              </Card.Body>
            </Card>
          </div>
        </Container>

        {/* — Services */}
        <Section.Container className="mb-10">
          <h2 className="mb-12">VIP Services:</h2>
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.services.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>     
        </Section.Container>

        {/* — Venues: Current */}
        <Section.Container large className="max-w-5xl relative md-10">
          <h2 className="mb-12">Our Venues:</h2>
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.venues.current.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
          <div className="w-60 mx-auto">
            {data.venues.sardine.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container>
        {/* — Venues: Next */}
        <Section.Container className="mb-10">
          <h2 className="mb-12">Coming Soon:</h2>
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {data.venues.current.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
         </Section.Container>
      </Section>

      {/** OFFER SECTION */}
      { userTier !== undefined  ?   <>
      <Section className="py-20 bg-secondary text-white">
        <Section.Container>
          {/* Hero #1 */}
          { userTier !== undefined   ?  
            <Hero className="mb-20">
                  <ImageSliderMember
                    className="w-526 h-350 md:w-60 md:h-64"
                    images={[
                      '../images/kclub/Copie-de-Copy-of-DSC01007-pqljpysbizi15efjg3q1yieyuf4m7pe5tr4x7qu3z4.jpg',
                      '../images/kclub/Copie-de-Copy-of-DSC00980-pqlkmphxl8blkeve9987t8yzrlqwc5dkbt7t0qaf8g.jpg',
                      '../images/kclub/DSC07733-2048x1152.jpg',
                      '../images/kclub/7U7A0722.jpg',
                      '../images/kclub/DSC07449-1-1-1-1-1-1.jpg',
                      '../images/kclub/DSC00029-1.jpg',
                      '../images/kclub/DSC00068-1-ppri8472znzui20qs8dkmtzga948pvpil37z7cfyqo.jpg',
                    ]}
                    settings={{
                      dots: false,
                      arrows: false,
                    }}
                  />
              <Hero.Body>
                <h3>{ userTier !== undefined 
                
                ?  userTier["MEMBER"].title :''}</h3>
                <hr className="mt-4 mb-4" />
                <div className="mb-8">
                  <List className="list-disc list-inside space-y-4">
                  { userTier !== undefined
                  
                  ? 
                        userTier["MEMBER"].list.map( (obj:any, index:any) => 
                        <List.Item  key={"MEMBER"+index}>{obj}</List.Item>
                    ):<></>}
                  </List>
                </div>
                <div  className="mb-10">
                  <p>
                    Unlike most NFT projects that sell hypothetical futures, we offer you real-life benefits that you can use immediately.
                  </p>
                </div>
                <div  className="mb-10">
                  <p>
                    The NFT represents your digital contract and is made of artwork created by the famous Balinese artist Monez!
                  </p>
                </div>
              </Hero.Body>
            </Hero>
          :<></>}
        </Section.Container>
      </Section>
      
      </>
      :<></>
      }
      <Footer />
    </div>
  )
 }
export default KabanaHome;
