import React from 'react'
import { Link } from 'react-router-dom'

import Container from './Container'
import logo from '../../assets/images/kabana-tribe-logo-vertical.png'

export default function Footer() {
  return (
    <footer className="footer text-primary" style={{ padding: '5% 0', backgroundColor: '#f9f4e8' }}>
      
      <Container className="mx-auto text-sm text-center">
        <div className="w-60 mx-auto">
          <Link to={'/'}>
            <img src={logo} className="mx-auto" alt="Kabana Tribe" />
          </Link>
        </div>
        <div className="row" style={{ paddingTop: '50px' }}/>
        <span
              className="mx-auto text-sm text-center"
              style={{
                width: 'auto',
                display: 'block',
              }} >
             
              <span
                className="badge "
                style={{
                  borderRadius: '5px',
                  fontSize: '18px',
                  backgroundColor: '#f9f4e8',
                  color: '#44594e',
                  borderColor: '#44594e',
                  borderWidth: '5px',
                  marginLeft: '5px',
                  marginTop: '5px',
                  padding: '0px 5px',
                }} >
                  <a target="_blank" title="K-Club Facebook" rel="noopener noreferrer" style={{ color: 'inherit' }} href="https://www.facebook.com/Kclububud/">
                    {' '}
                    <span className="fa fa-facebook fa-fw " style={{ padding: '7px' }}></span>
                  </a>
              </span> 
              <span
                className="badge "
                style={{
                  borderRadius: '5px',
                  fontSize: '18px',
                  backgroundColor: '#f9f4e8',
                  color: '#44594e',
                  borderColor: '#44594e',
                  borderWidth: '5px',
                  marginLeft: '5px',
                  marginTop: '5px',
                  padding: '0px 0px',
                }} >
                  <a
                    target="_blank"
                    title="Instagram"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit' }}
                    href="https://www.instagram.com/kclububud/"
                  >
                    <span className="fa fa-instagram " style={{ padding: '7px' }}></span>
                  </a>
              </span>
              <span
                className="badge  "
                style={{
                  borderRadius: '5px',
                  fontSize: '18px',
                  backgroundColor: '#f9f4e8',
                  color: '#44594e',
                  borderColor: '#44594e',
                  borderWidth: '5px',
                  marginLeft: '5px',
                  marginTop: '5px',
                  padding: '0px 0px',
                }} >
                  <a target="_blank" title="Spotify" rel="noopener noreferrer" style={{ color: 'inherit' }} href="https://open.spotify.com/playlist/4OsxtqaZSjBRUCsyPK4OGE?si=I1_Z3bksT4GB2C_1x9MYJg&utm_source=whatsapp">
                    {' '}
                    <span className="fa fa-music " style={{ padding: '7px' }}></span>
                  </a>
              </span>
              <span
                className="badge  "
                style={{
                  borderRadius: '5px',
                  fontSize: '18px',
                  backgroundColor: '#f9f4e8',
                  color: '#44594e',
                  borderColor: '#44594e',
                  borderWidth: '5px',
                  marginLeft: '5px',
                  marginTop: '5px',
                  padding: '0px 0px',
                }} >
                <a target="_blank" title="Google" rel="noopener noreferrer" style={{ color: 'inherit' }}href="https://www.google.com/travel/hotels/entity/CgsIqLLcrYviu-i5ARAB/reviews?g2lb=2502548%2C2503771%2C4258168%2C4270442%2C4306835%2C4308226%2C4317915%2C4371334%2C4401769%2C4419364%2C4429192%2C4482438%2C4486153%2C4509341%2C4515403%2C4533882%2C4536454%2C4545890%2C4552139%2C4554491%2C4560084%2C4270859%2C4284970%2C4291517&hl=en-ID&gl=id&ssta=1&rp=EKiy3K2L4rvouQEQqLLcrYviu-i5ATgCQABIAcABAg&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwjw2cPf0ITxAhUAAAAAHQAAAAAQAg&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgyZGQyMTllNWRjZjY5M2E1OjB4YjlkMGVmMTBiNWI3MTkyOBoAEhoSFAoHCOUPEAYYCRIHCOUPEAYYChgBMgIQACoJCgU6A0lEUhoA ">
                  {' '}
                  <span className="fa fa-google " style={{ padding: '7px' }}></span>
                </a>
              </span>
            </span>
          <ul className="mt-2 py-4">
            <li>
              <h4>Sales Office</h4> 
            </li>
          <li>Jl. Petitenget No.9, Kerobokan Kelod, Kec. Kuta Utara, Kabupaten Badung, Bali</li>
          <li>
              <h4>KabanaTribe Office</h4>
          </li>
          <li>Jalan Raya Cebok , Kedisan Tegallalang, Kedisan, Kecamatan Ubud, Kabupaten Gianyar, Bali 80561</li>
          {/* <li>Terms and conditions</li>
          <li>Privacy Policy</li> */}
        </ul>
        <div className="footer-copy">&copy; COPYRIGHT KABANA 2024</div>
      </Container>
    </footer>
  )
}
